// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap";
import { service } from "./service.js";
export default {
    data() {
        return {
            data: {},
            transaction_status: '',
            invoice_no: '',
            invoice_date: '',
            invoice_total: '',
            description: '',
            price: '',
            quantity: '',
            months: '',
            usd_price: '',
            vnd_price: '',
            point: '',
            payment_gateway: '',
            total_vnd: ''
        }
    },
    mounted() {
        this.initPayment()
    },
    methods: {
        initPayment() {
            this.data = this.$route.query

            if (this.data.vnp_ResponseCode) return this.vnPayInit()
            // paypal init
        },
        vnPayInit() {
            this.transaction_status = (this.data.vnp_ResponseCode === '00') ? true : false
            this.invoice_no = this.data.vnp_TxnRef
            this.invoice_date = this.vnPayDateParse(this.data.vnp_PayDate)
            this.invoice_total = `$${this.data.vnp_Amount / 2300000}`
            this.description = this.data.vnp_OrderInfo
            this.price = `$${this.data.vnp_Amount / 2300000}`
            this.quantity = 1
            this.months = (this.data.vnp_Amount / 2300000 === 126) ? 6 : 12
            this.usd_price = `$${this.data.vnp_Amount / 2300000}`
            this.vnd_price = `${(this.data.vnp_Amount / 100).toLocaleString()}VND`
            this.point = this.data.vnp_Amount / 2300000
            this.payment_gateway = 'VN PAY'
            this.total_vnd = `${(this.data.vnp_Amount / 100).toLocaleString()}VND`

            service.checkVnpTxnRef(this.data, (res) => {
                console.log(res.data)
                if (!res || !res.data || res.data !== 'OK') {
                    this.transaction_status = false
                }
            })
        },
        paypalInit() {

        },
        vnPayDateParse(date) {
            let year = date.substring(0, 4)
            let month = date.substring(4, 6)
            let day = date.substring(6, 8)
            let hour = date.substring(8, 10)
            let min = date.substring(10, 12)
            let sec = date.substring(12, 14)
            return `${hour}:${min}:${sec} ${day}/${month}/${year}`
        }
    }
}